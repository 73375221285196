.page-header {

  .no-header {
    height: 0 !important;
  }

  .header-image {
    background-size: cover !important;
    background-position: center !important;

    &--mobile {
      height: 42vh;
    }

    &--mobile-overflow {
      height: 30vh;
    }

    &--desktop {
      padding-top: 32%;
    }
  }

}
