.plt-desktop {
  .practical_info_content_iframe {
    --padding-end: 0 !important;
    --padding-start: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    height: 100%;
  }
}

.practical_info_page {

  /* for all pages */

  ion-item {
    cursor: pointer;
    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 1;
  }

  ion-avatar {
    --border-radius: 4px;
  }

  .subtitle {
    font-size: 15px;
    font-weight: normal;
    font-family: 'Roboto Condensed Light', sans-serif;

    ion-badge {
      font-size: 15px;
      font-weight: normal;
      line-height: initial;
    }
  }

  .title {
    margin-top: 4px;
  }


  /* CFF page */

  .time-info {
    font-size: 19px;
  }

  .with-fixed-size {
    height: 55px;
    width: 55px;
    margin-bottom: 2px;
    margin-top: 0;
  }

  /*restaurant page*/

  .card_cicg_color {
    ion-card {
      --background: var(--ion-color-primary);
      --color: white;

      hr.white-hr {
        background-color: white;
      }

      h1 {
        margin-top: 0;
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .slide-container-weekly-menu-card {
    position: relative;

    .slides-weekly-menu-card {

      .swiper-wrapper {
        padding-bottom: 16px;

        .weekly-menu-card {
          border-top: 5px solid var(--ion-color-primary);
          min-height: 375px;
          width: 100%;

          h1 {
            color: var(--ion-color-primary);
          }
        }
      }
    }

    .arrow {
      &--back {
        left: -30px;
      }

      &--forward {
        right: -30px;
      }
    }

  }

  /* TPG page */
  .tpg_departure {

    .line-5 {
      background-color: #3ccaf9 !important;
    }

    .line-8 {
      background-color: #7d4d28 !important;
    }

    .line-11 {
      background-color: #7b79ba !important;
    }

    .line-15 {
      background-color: #8c5531 !important;
    }

    .line-22 {
      background-color: #423996 !important;
    }

    .item-icon-image {
      height: 55px;
      width: 55px;
      border-radius: 4px;

      .initials-container {
        height: 55px;

        .initials {
          font-size: 2rem !important;
        }

      }
    }

  }

  /* Tourism page */

  .attraction-list {
    .item-icon-image {
      .initials-container {
        .initials {
          font-size: 2rem !important;
        }
      }
    }
  }


}

.iframe-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  iframe {
    border: none;
  }

  &__errorMessage {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    h2 {
      text-align: center;
    }
  }
}
