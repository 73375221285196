.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.margin-left-right-auto {
  margin-left: auto;
  margin-right: auto;
}

.radius-top-4 {
  border-radius: 4px 4px 0 0;
}

.bg-white {
  background: var(--ion-background-color,#fff);
}

p.small-margin {
  margin-block-start: 0;
  margin-block-end: 0.5em;
}

.ion-no-padding, [no-padding] {
  --padding-end: 0 !important;
  --padding-start: 0!important;
  --padding-top: 0!important;
  --padding-bottom: 0!important;
}

.clickable {
  cursor: pointer;
}

.bottom-absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.no-border {
  border: none !important;
}

.url-link {
  color: var(--ion-color-primary);
  cursor: pointer;
  text-decoration: underline!important;
}

.text-white {
  color: #fff;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-margin-top {
  margin-top: 0;
}

.no-padding {
  padding: 0;
}

.element-center {
  margin:0 auto;
}

.small-margin-top-only {
  margin: 8px 0 0;
}
