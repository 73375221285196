/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
/*@import "~@ionic/angular/css/typography.css";*/
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* MOBISCROLL */
@import "mobiscroll";
@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";

@import "./theme/fonts";
@import "./theme/typography";
@import "./theme/utilities";
@import "./theme/menu";
@import "./theme/practical_info";
@import "./theme/event";
@import "./theme/card.scss";
@import "./theme/page-header.scss";
@import "./theme/avatar.scss";
@import "./theme/settings.scss";



// Remove scrollbars
::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important; /* make scrollbar transparent */
}

html {
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
}

.custom-pane {
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.header-row {
  margin-top: 2rem !important;
  padding: 0 0.6rem !important;
  ion-col {
    padding: 0 !important;
  }
  h2, h4, p{
    margin: 0 !important;
  }
}

.session-card {
  margin-top: 16px !important;
}

.event-information {
  box-shadow: 0 -5px 5px -5px lightgrey;
}

#file-carousel-container .swiper-wrapper {
  margin-bottom: 13px !important;
}

.home-hr {
  margin: 2.5rem 1rem 0 1rem !important;
}

.see-all {
  color: var(--ion-color-see-all);
  cursor: pointer;
}

.read-more {
  color: var(--ion-color-primary) !important;
  font-size: 15px !important;
  margin-top: 5px
}

.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background: #fcfafa;
  opacity: 1;
  border: solid 0.5px #999899 !important;
}

.swiper-pagination-bullet-active {
  width: 9px !important;
  height: 9px !important;
  background: #000000 !important;
  border: solid 0.5px #000000 !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.video-carousel-container {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    position: relative;
  }
}

.badge-icon {
  /* NOTE Important since the class .swiper-slide img takes the precedence */
  width: 11px!important;
  vertical-align: middle;
}

.badge-icon-title {
  width: 11px !important;
}

#cover-container, .default_bg {
  background-size: cover !important;
  background-position: center !important;
  background: var(--ion-color-primary); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* Standard syntax */
}

.default_bg_no_alpha {
  background-size: cover !important;
  background-position: center !important;
  background: var(--ion-color-primary); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, var(--ion-color-primary), var(--ion-color-dark)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, var(--ion-color-primary), var(--ion-color-dark)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, var(--ion-color-primary), var(--ion-color-dark)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, var(--ion-color-primary), var(--ion-color-dark)); /* Standard syntax */
}

/*.event-card-logo-image {
  position: relative;
  width: 4rem !important;
  height: 4rem !important;
  background-color: rgba(255,255,255,0.0);
  background-repeat: no-repeat;
  background-size: contain;
}*/

.has-no-logo {
  background-color: #d9d9d9 !important;
}

.event-card-ongoing-badge-unpublished {
  background-color: #9a9393;
}

.icon-hidden {
  width: 30%;
  margin: 13px 0;
}

.mt-event-modal {
  --height: auto;
  .modal-wrapper {
    //width: 97% !important;
    //left: 1.5% !important;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  ion-backdrop {
    opacity: 0.4 !important;
  }
  #login-container {
    background-color: white !important;
  }
}

.alert-message.sc-ion-alert-md {
  max-height: 100%;
}

.loading-modal {
  color: var(--ion-color-primary);
  .loading-content {
    color: var(--ion-text-color);
  }
}

#video-container-mobile{
  text-align: center;
}

#video-container-mobile iframe {
  width: 100% !important;
  height: 100% !important;
}

#video-container-desktop{
  text-align: center;
}

#video-container-desktop iframe {
  width: 100% !important;
}

#menu-header {
  height: 100px;
  background: var(--menu-header-background);
  background-repeat: no-repeat;
  background-size: cover;
}

.menu-events-divider {
  margin: 0 16px;
  color: #999899;
}

.menu-events-divider-last {
  margin: 0 !important;
}

.mbsc-cal-mark {
  background: var(--ion-color-primary) !important;
}

.fake-button {
  color: white !important;
  background-color: var(--ion-color-primary);
  padding: 8px 12px !important;
  border-radius: 2px !important;
  cursor: pointer;
}

.video-popover {
  --max-height: 30vh;
  .modal-wrapper {
    margin: 0 1.5% !important;
  }
}

ion-card.carrousel-card-item {
  min-height: 100%;
}

/* HIDE SELECTOR LANGUAGE ANS SUBTITLE FOR VIDEO CAROUSEL*/
.video-cover {
  .player-video {
    .video-section {
      height: 100%;
      ion-list.video-select-options-container {
        display: none!important;
      }
    }
  }
}

//Desktop specific
/*@media all and (min-width: 769px) {*/
.plt-desktop {
  .custom-pane {
    margin: 1.2rem auto 0 auto !important;
  }

  .event-information {
    box-shadow: none;
  }

  .custom-divider {
    background-color: #ffffff !important;
  }

  #my-menu {
    margin: 4px 1.25rem 4px 4px !important;
    border: none !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.20) !important;
    border-radius: 5px !important;
    /*height: 77vh !important;*/

    ion-content {
      --overflow: hidden;
      overflow: auto;
      -ms-overflow-style: none !important;
      overflow: -moz-scrollbars-none !important;
      scrollbar-width: none;
      --offset-bottom: auto!important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .split-pane-visible>.split-pane-main.my-nav {
    margin: 4px 1.25rem 4px 4px !important;
    border: none !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.20) !important;
    border-radius: 5px !important;
    /*height: 98% !important;*/
  }

  .item {
    background-color: #ffffff;
  }

  #menu-slide {
    border-radius: 4px !important;
  }

  #menu-header {
    border-radius: 4px 4px 0 0 !important;
    height: 150px !important;
  }

  .menu-card {
    border-radius: 0 !important;
  }

  .my-nav ion-content {
    --padding-end: 3.4rem;
    --padding-start: 3.4rem;
    --padding-top: 1.7rem;
    --padding-bottom: 1.7rem;
    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

  }

  .my-nav ion-content[no_padding] {
    --padding-end: 0 !important;
    --padding-start: 0!important;
    --padding-top: 0!important;
    --padding-bottom: 0!important;
  }

  .content-padding {
    padding: 1.7rem 3.4rem 1.7rem 3.4rem;
  }

  #menu-slide ion-content {
    padding: 0 !important;
  }

  .card-content {
    background-color: #ffffff !important;
  }

}

img {
  -ms-interpolation-mode: bicubic;
}

//ion-modal {
//  //--max-height: 100%!important;
//  --height: auto;
//  --min-height: 200px;
//}

//.mt-event-modal {
//  --height: auto;
//  --min-height: 200px;
//}

.splash-modal .modal-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
}

.btn-badge {
  &__button {
    background-color: var(--ion-color-primary);
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
    font-weight: normal;
    z-index: 9999;
    border-radius: 10px;
    padding: 2px 8px 3px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__header-card {
      position: absolute;
      top: 20px;
      right: 5px;
    }

    &__footer-card {
      margin-right: 10px;
    }

    .badge-icon {
      width: 11px !important;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.btn-badge:hover{
  background-color: black;
}

.btn-badge__button__footer-card:hover{
  background-color: var(--ion-color-primary);
}

/**
* Resize modal for video display only
 */

.custom-modal-size {
  .modal-wrapper {
      --width: 600px!important;
      --height: auto;
    /*height: auto;*/
    min-height: calc(9 * var(--width)/16);
    position: relative;
    background-color: transparent;

    video-full {
      top: 0;
      background-color: transparent;
    }

    #video-container {

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  #close-button {
    position: absolute;
    top: -7px;
    right: -16px;
    color: var(--ion-color-primary);
  }
}

// fix for the no script to show in the body
html:not(.hydrated) body {
  display: initial !important;
  app-root {
    display: none !important;
  }
}


@media only screen and (max-width: 768px) {
  .custom-modal-size {
    .modal-wrapper {
      --width: 100%!important;
      --height: auto;
      position: relative;
      padding-top: calc(56.25% + 30px);
      min-height: auto;

      video-full {
        top: 0;
        padding-top: 30px;
      }

      #close-button {
        position: absolute;
        top: -15px;
        right: -16px;
        color: var(--ion-color-primary);
      }

      #video-container {

        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}

/**
*
* Specific fixes for IE 10&11
*
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10 & 11 */
  .click-block { // IE doesn't like this click-block, so hide it
    display: none;
  }

  // give our form elements some height since IE doesn't support the Ionic code that takes care of this
  input, ion-label {
    min-height: 1.4em;
  }

  ion-input, ion-textarea {
    min-height: 2.8em;
  }

  .item-block {
    text-align: left;
  }

  .custom-pane {
    margin: 2rem 15% !important;
  }

  ion-card img {
    width: auto !important;
  }

  .survey-submit-button {
    position: absolute !important;
  }

  .cookie-law .cookie-law-wrapper {
    position: absolute !important;
  }

  div.tabbar {
    display: table !important;
  }

  a.tab-button {
    display: table-cell !important;
    vertical-align: middle !important;
  }

  .badge-icon-title {
    width: 11px !important;
  }

  #profile-button-image{
    height: 21px !important;
  }

}

