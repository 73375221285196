@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/fonts/BebasNeueBold.ttf') format('truetype'),
  url('./assets/fonts/BebasNeueBold.otf') format('opentype');
}


@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Condensed Light';
  src: url('./assets/fonts/RobotoCondensed-Light.ttf');
}
