:host {
  font-size: 15px;
}

html.md, html.ios {
  --ion-default-font: "Roboto Condensed", "Helvetica Neue", sans-serif;
}

body {
  color: var(--ion-text-color);
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 15px;
  font-weight: normal;
}

/*ul {
  li {
    font-size: 15px;
  }
}*/

/*div {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 15px;
  font-weight: normal;
}*/

h1 {
  font-family: 'Bebas Neue',sans-serif !important;
  font-size: 28px;
  font-weight: normal !important;
}

h2 {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 23px;
  font-weight: normal !important;
}

h3 {
  font-family: 'Bebas Neue',sans-serif !important;
  font-size: 19px;
  font-weight: normal !important;
}

h4 {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 18px;
  font-weight: normal !important;
}

h5 {
  font-family: 'Roboto Condensed Light', sans-serif !important;
  font-size: 15px;
  font-weight: normal !important;
}

.app-title {
  font-family: 'Bebas Neue', sans-serif !important;
  font-size: 19px !important;
  color: var(--ion-color-primary);
  //text-align: center !important;
  padding-top: 5px !important;
}

/*ion-card p {
  font-size: 15px !important;
  line-height: normal !important;
}*/

ion-card {
  background: #ffffff !important;
  -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.07) !important;
  -moz-box-shadow:    0 0 16px 0 rgba(0, 0, 0, 0.07) !important;
  box-shadow:         0 0 16px 0 rgba(0, 0, 0, 0.07) !important;
}

button {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 17px;
  text-transform: none !important;
}

/*p {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 15px;
  font-weight: normal !important;
}*/

a {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: normal !important;
  color: var(--ion-color-primary);
}

.linkify {
  color: var(--ion-color-primary);
  cursor: pointer;
  text-decoration: underline;
}

hr {
  background-color: rgba(0,0,0,.08) ;
}
