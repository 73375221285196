.item-icon-image {
  position: relative;
  background-color: #d9d9d9;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &--rounded {
    border-radius: 50%;
  }
}

.initials-container {
  /*font-size: 24px;*/
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  color: #ffffff;

  .initials {
    display: table-cell;
    vertical-align: middle;
    /*font-family: 'Bebas Neue', sans-serif !important;*/
    font-size: 19px !important;
    line-height: 19px;
    font-weight: 700 !important;
  }

}

.event-card-logo-image {
  position: relative;
  width: 4rem !important;
  height: 4rem !important;
  background-color: rgba(255,255,255,0.0);
  background-repeat: no-repeat;
  background-size: contain;
}

.has-no-logo {
  background-color: #d9d9d9 !important;
}

.item-icon-square {
  --border-radius: 0;
}

.image-center {
  margin:0 auto;
}
