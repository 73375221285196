.event-card-ongoing-badge-unpublished {
  background-color: #9a9393;
}

.event-header-container {
  position: relative;

  .event-header {
    background-size: cover !important;
    background-position: center !important;
    padding-top: 25%;
  }

  .event-header-mobile {
    background-size: cover !important;
    background-position: center !important;
    height: 30vh;
  }

  .card-ongoing-badge {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
    border-radius: 10px;
    padding: 2px 8px 3px 8px;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 14px;

    &__unpublished {
      --background: grey;
    }
  }
}

.event-tabs {

  position: relative !important;


  ion-tab-bar {
    justify-content: space-evenly;

    ion-tab-button {
      max-width: unset;

      ion-label {
        font-size: 1.2rem;

        @media all and (max-width: 768px) {
          font-size: 0.8rem;
        }
      }
    }

    .tab-selected {
      border-bottom: solid 3px var(--ion-color-primary);
      border-top: none;

      @media all and (max-width: 768px) {
        border-top: solid 3px var(--ion-color-primary);
        border-bottom: none;
      }
    }


  }

  .event-information-container {

    &__mobile {
      position: absolute;
      left: 0;
      right: 0;
      top: 23vh;
    }

    .event-information {
      position: relative;

      .card-ongoing-badge {
        position: absolute;
        right: 0;
        top: -10px;
        z-index: 9999;
        border-radius: 10px;
        padding: 2px 8px 3px 8px;
        font-family: 'Roboto Condensed Light', sans-serif;
        font-size: 14px;

        &__content {
          position: initial;

          &__button {
            background-color: var(--ion-color-primary);
            color: white;
            font-family: "Roboto Condensed", sans-serif;
            font-size: 15px;
            font-weight: normal;
          }
          &__button:hover{
            background-color: black;
          }
        }

        &__unpublished {
          --background: grey;
        }
      }



      ion-item-divider {
        min-height: 0;
      }


    }
  }

  .event-information-container, .event-participants-container {


  }

  .person-card {
    --min-height: 95px;
    ion-avatar {
      height: 55px;
      width: 55px;
    }

    .title {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 28px;
    }
  }
}

.plt-mobile {
  .info-padding {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.event-news-container {

  &__mobile {
    position: absolute;
    top: 23vh;
    left: 0;
    right: 0;
  }

}



.plt-desktop .event-carousel-container {
  position: relative;

  .event-slide {
    display: block;
  }

  .arrow {
    &--back {
      left: -12px;
    }

    &--forward {
      right: -12px;
    }
  }
}

.agenda-icon {
  width: 2rem!important;
  cursor: pointer;
}

.edit-profile-picture {

  ion-avatar {
    position: relative;
    width: 96px;
    height: 96px;
  }

  .delete-picture-button {
    position: absolute;
    top: -10px;
    right: -15px;
  }
}


.cropper {
  /*max-height: 100vw;
  min-height: 200px;*/

  height: 428px;

  img {
    display: block ;
    height:auto;
    width:100%;
    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    max-height: 428px;
  }
}

@media (min-width: 768px) {
  .cropper {
    /*max-height: 400px;*/
  }
}


.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-i {
  min-height: 3em;
}

.mbsc-mobiscroll .mbsc-lv {
  background: inherit;
}

.mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-days, .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-day {
  text-align: center;
}

.session-card-container {
  position: relative;

  .session-card-ongoing-badge {
    position: absolute;
    right: 1px;
    top: -9px;
    z-index: 9999;
    border-radius: 10px;
    padding: 2px 8px 3px 8px;
    font-family: 'Roboto Condensed Light', sans-serif;
    font-size: 14px;
  }
}
