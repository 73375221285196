.news-card {
  cursor: pointer;

  .no-img-news {
    height: 0;
    padding-top: 0;
  }

  .img-news {
    background-size: cover;
    background-position: center;
    padding-top: 41%;
  }
}

.event-card-header-img {
  background-size: cover !important;
  background-position: center !important;

  padding-top: 41%;
  background: var(--ion-color-primary); /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, var(--ion-color-primary-alpha), var(--ion-color-dark-alpha)); /* Standard syntax */
}

.span-sub {
  vertical-align: sub;
}

.start-date-container {
  width: 70%;
}

.start-date-separator {
  width: 100%;
  height: 2rem;
  background-image: url('src/assets/imgs/event_card_date_separator.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.event-card-end-date {
  margin: 0;
}

.event-card-day {
  color: var(--ion-color-primary);
  font-size: 1.2rem;
  line-height: 1.1;
  margin: 0;
}

.event-card-month {
  color: #999899;
  font-size: 0.6rem;
  line-height: 1.1;
  margin: 0;
}

.event-card-date {
  border-left: solid #d9d9d9 1px;
}
